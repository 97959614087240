.bg-image {
  background-image: url('/img/signups-half.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
}

.App {
  z-index: 1;
  padding: 20px;
}

.content-area {
  padding: 25px;
}

.ant-layout-content .ant-row-flex {
  height: 100vh;
}
.login-form-button {
  font-weight: 600;	
  width: 150px;
  line-height: 12px;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 10px 35px;
  transition: 250ms box-shadow ease-in-out;
  border: 0;
  color: #FFFFFF;
  background-color: #395AFF;
  border-radius: 50px;
  height: 50px;
}

.login-form-button:hover {
  background-color: #395AFF;
  color: #FFFFFF;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
}

.ant-steps-item-description {
  font-size: 10px;
}

.ant-steps-item-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 18px !important;
}

.Form .ant-input {
	border: 1px solid #DBDEE9;	border-radius: 5px;
}

.Form {
  margin: 0 auto;
  width: 100%;
}

.Form h1 {
  font-size: 60px;
  line-height: 73px;
  color: black;
}

.Form h4 {
  font-size: 16px;	
  font-weight: 600;	
  line-height: 19px;
  color: black;
}

.Form p {
  font-size: 14px;	
  line-height: 18px;
  color: #4A4A4A;
}

.Form .input-block {
  padding: 40px 0;
}

.ant-input-lg {
  line-height: 35px;
  font-size: 12px;	
  line-height: 15px;
  height: 35px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A6ABBE;
  font-size: 12px;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #A6ABBE;
  font-size: 12px;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #A6ABBE;
  font-size: 12px;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
  height: 100%;

}

.side-nav__method-row .ant-form-item-label label {
  color: #A6ABBE;
  font-weight: 400;
}

.side-nav__method-row img {
  filter:  grayscale(100%);
}

.side-nav__method-row .ant-radio-button-wrapper-checked img {
  filter:  grayscale(0%);
}

.terms {
  margin-top: 10px;
  float: left;
  font-size: 10px;	
  line-height: 13px;
  color: black;
}

@media (min-width: 768px) {
.Form {
  max-width: 500px;
}

.Form .ant-form-item:not(.side-nav__method-row) {
  width: 300px;
}
}


@media  (max-width: 768px) {
  .Form h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
